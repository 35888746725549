import * as keycloak from '../keycloak';
import { IAccountInfo } from '../userSearch/UserSearchTypes';

export function getKeycloakUserInfo(userId: string) {
  return fetch(`https://sso-stage.chronotrack.com/auth/admin/realms/chronotrack/users/` + userId, {
    headers: {
      Authorization: 'Bearer ' + keycloak.getToken()
    }
  })
    .then(response => {
      if (response.status === 200) {
        return response.text();
      }
      throw new Error(response.statusText);
    })
    .then(text => {
      const json = JSON.parse(text);
      return json as IAccountInfo;
    });
}
