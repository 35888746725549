import * as keycloak from '../keycloak';

export function getAuthorizedList() {
  return fetch(process.env.REACT_APP_IOT_API_URL + '/authorization/list', {
    headers: {
      Authorization: 'Bearer ' + keycloak.getToken()
    }
  })
    .then(response => {
      if (response.status === 200) {
        return response.text();
      }
      throw new Error(response.statusText);
    })
    .then(text => {
      const json = JSON.parse(text);
      return json;
    });
}
