import {
  Button,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow
} from '@material-ui/core'
import { VerifiedUser } from '@material-ui/icons';
import React from 'react'

const useStyles = makeStyles({
  table: {
    minWidth: 400,
  },
});

function RemoteControlTable({
  macs,
}: {
  macs: string[],
}) {
  const classes = useStyles();
  return (
    <TableContainer component={Paper}>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell>Controllers</TableCell>
            <TableCell align="right">Authorize</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {macs.map(mac => (
            <TableRow key={mac}>
              <TableCell component="th" scope="row">{mac}</TableCell>
              <TableCell align="right">
                <Button
                  startIcon={<VerifiedUser />}
                  variant='contained'
                >Authorized</Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
        <TableFooter>
        </TableFooter>
      </Table>
    </TableContainer>
  )
}

export default RemoteControlTable