import { combineReducers, configureStore } from "@reduxjs/toolkit";
import darkMode from "./reducers/darkMode";
import header from "./reducers/header";
import open from "./reducers/open";

export default configureStore({
  reducer: combineReducers({
    darkMode,
    header,
    open
  })
});
