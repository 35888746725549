import React from 'react'
import {
  Snackbar,
  Typography
} from '@material-ui/core'
import { Alert } from '@material-ui/lab'

function DialogSuccess (
  { message, onClose}: 
  { message?: string, onClose: () => void}) {
  return (
    <Snackbar
				anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
				data-cy="dialogSnackbar"
				open={!!message}>
				<Alert severity="success" onClose={onClose}>
					<Typography>
						{message}
					</Typography>
				</Alert>
			</Snackbar>
  )
}

export default DialogSuccess
