import React from 'react'
import { Route } from 'react-router-dom'
import keycloak from '../keycloak'
import UnauthorizedPage from './UnauthorizedPage'

function ProtectedRoute({
  component,
  path,
  resource,
  role,
}: {
  component: React.FC,
  path: string,
  resource: string,
  role: string
}) {
  return (
    <Route path={path} component={keycloak.hasResourceRole(role, resource) ? component : UnauthorizedPage} />
  )
}

export default ProtectedRoute