import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface IOpen { [key: string]: boolean }

const slice = createSlice({
  name: 'open',
  initialState: {
    drawer: false
  } as IOpen,
  reducers: {
    setOpen: (state, action: PayloadAction<{ key: string, open: boolean }>) => {
      state[action.payload.key] = action.payload.open
    }
  }
})

export const setOpen = slice.actions.setOpen

export default slice.reducer
