import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface IHeader {
  lastUpdated: number
  subtitle: string
  title: string
}

const slice = createSlice({
  name: 'header',
  initialState: {
    lastUpdated: new Date().getTime(),
    subtitle: '',
    title: 'Hub',
  } as IHeader,
  reducers: {
    updateLastUpdated: (
      state
    ) => {
      state.lastUpdated = new Date().getTime()
    },
    updateTitle: (
      state,
      action: PayloadAction<{
        subtitle: string,
        title: string
      }>
    ) => {
      state.subtitle = action.payload.subtitle
      state.title = action.payload.title
    }
  },
})

export const updateLastUpdated = slice.actions.updateLastUpdated
export const updateTitle = slice.actions.updateTitle

export default slice.reducer
