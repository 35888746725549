import {
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@material-ui/core'
import React from 'react'
import { IUserInfo } from '../userSearch/UserSearchTypes';

const useStyles = makeStyles({
  table: {
    minWidth: 400,
  },
});

function UserInfoTable({
  userInfo,
}: {
  userInfo: IUserInfo,
}) {
  const classes = useStyles();
  const { ctlive, keycloak, timersportal } = userInfo
  return (
    <TableContainer component={Paper}>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell>&nbsp;</TableCell>
            <TableCell>CT Live</TableCell>
            <TableCell>Keycloak</TableCell>
            <TableCell>Timers Portal</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell component="th" scope="row">username</TableCell>
            <TableCell>{ctlive.username}</TableCell>
            <TableCell>{keycloak.username}</TableCell>
            <TableCell>{timersportal.username}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">email</TableCell>
            <TableCell>{ctlive.email}</TableCell>
            <TableCell>{keycloak.email}</TableCell>
            <TableCell>{timersportal.email}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">first name</TableCell>
            <TableCell>{ctlive.firstName}</TableCell>
            <TableCell>{keycloak.firstName}</TableCell>
            <TableCell>{timersportal.firstName}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">last name</TableCell>
            <TableCell>{ctlive.lastName}</TableCell>
            <TableCell>{keycloak.lastName}</TableCell>
            <TableCell>{timersportal.lastName}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default UserInfoTable