import React from 'react';
import { Provider as ReduxProvider } from 'react-redux'
import {
  BrowserRouter,
  Switch as RouterSwitch,
  Route
} from 'react-router-dom'
import {
  createMuiTheme,
  CssBaseline,
  makeStyles,
  ThemeProvider,
} from '@material-ui/core';

import './App.css';
import AppDrawer, { DRAWER_WIDTH } from './AppDrawer';
import AppHeader from './AppHeader';
import AppFooter from './AppFooter'
import RemoteControlPage from '../remoteControl/RemoteControlPage';
import UserSearchPage from '../userSearch/UserSearchPage';
import CcsControllersPage from '../ccs/CcsControllersPage';
import UserInfoPage from '../userInfo/UserInfoPage';
import ProtectedRoute from './ProtectedRoute';
import NotFoundPage from './NotFoundPage';
import store from '../store';

store.subscribe(() => {
  console.log('state', store.getState())
})

const useStyles = makeStyles(theme => ({
  main: {
    marginLeft: DRAWER_WIDTH,
    [theme.breakpoints.down('md')]: {
      marginLeft: 0
    }
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
}))

function AppRoot() {
  const classes = useStyles()
  const theme = React.useMemo(
    () => createMuiTheme({
      palette: {
        type: 'light',
        primary: {
          contrastText: 'rgb(74, 74, 74)',
          main: '#fff'
        }
      },
      typography: {
        h6: {
          color: 'rgb(249, 122, 45)',
          textTransform: 'uppercase'
        },
        h5: {
          color: 'rgb(74, 74, 74)',
          textTransform: 'uppercase'
        },
        h4: {
          color: '#00B0FF',
          textTransform: 'none',
          fontWeight: 'bold'
        },
        subtitle1: {
          color: 'rgb(74, 74, 74)',
          textTransform: 'none'
        }
      },
      overrides: {
        MuiButton: {
          containedPrimary: {
            backgroundColor: '#00B0FF',
            color: '#FFF',
            '&:hover': {
              backgroundColor:'#00B0FF'
            }
          },
          outlinedPrimary: {
            color: '#00B0FF'
          }
        },
        MuiLink: {
          root: {
            color: '#00B0FF'
          }
        }
      },
    }), [])
  return (
    <ReduxProvider store={store}>
      <ThemeProvider theme={theme}>
        <div className="App">
          <CssBaseline />
          <BrowserRouter>
            <AppHeader />
            <AppDrawer />
            <main className={classes.main}>
              <div className={classes.toolbar} />
              <RouterSwitch>
                <Route path="/" exact={true} component={CcsControllersPage} />
                <Route path="/controllers" component={CcsControllersPage} />
                <Route path="/remotecontrol" component={RemoteControlPage} />
                <ProtectedRoute path="/userinfo/:userId" component={UserInfoPage} resource='realm-management' role='manage-users' />
                <ProtectedRoute path="/usersearch" component={UserSearchPage} resource='realm-management' role='manage-users' />
                <Route path="*" component={NotFoundPage} />
              </RouterSwitch>
              <AppFooter />
            </main>
          </BrowserRouter>
        </div>
      </ThemeProvider>
    </ReduxProvider>
  );
}

export default AppRoot;
