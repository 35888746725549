import React, { MouseEventHandler } from 'react'
import {
	Box,
	Button,
	DialogActions,
	DialogContent,
	DialogTitle,
	makeStyles,
	Typography
} from '@material-ui/core'
import PhonelinkRingIcon from '@material-ui/icons/PhonelinkRing';
import DialogHeaderClose from '../shared/DialogClose';

const useStyles = makeStyles(theme => {
	return ({
		dialogHeaderIcon: {
			fontSize: "8rem"
		},
		dialogActions: {
			justifyContent: "space-between",
			margin: "15px 5px 5px"
		}
	})
})

function ControllerActivatedDialog({
	handleCloseDialog,
	handleAddAnother,
}:
	{
		handleCloseDialog: MouseEventHandler,
		handleAddAnother: MouseEventHandler,
	}) {
	const classes = useStyles();
	return (
		<>
			<DialogTitle>

				<Box display='flex' flexDirection='column' justifyContent='center' color='#5FC2A2'>
					<Typography variant='h4' color='inherit' align='center'>MobileTrack Activated</Typography>
					<Typography variant='subtitle1' color='textPrimary' align='center'>You've added the MobileTrack Controller Successfully</Typography>
				</Box>
				<DialogHeaderClose handleCloseDialog={handleCloseDialog} />

			</DialogTitle>

			<DialogContent>

				<Box display='flex' justifyContent='space-evenly' color='#5FC2A2'>
					<PhonelinkRingIcon className={classes.dialogHeaderIcon} fontSize='inherit' />
				</Box>

			</DialogContent>

			<DialogActions className={classes.dialogActions}>

				<Button onClick={handleAddAnother}>Add Another</Button>
				<Button
					onClick={handleCloseDialog}
					variant="contained"
					color='primary'
				>
					View Controllers
				</Button>

			</DialogActions>
		</>
	)
}

export default ControllerActivatedDialog