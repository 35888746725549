import { Box } from '@material-ui/core'
import React, { Component } from 'react'
import AuthorizeInput from './RemoteControlAuthorizeInput'

interface Props {
  onCancel: () => void
  onCode: (code: string) => void
}

class RemoteControlAuthorize extends Component<Props, {
  autoFocusIndex: number,
  characters: Array<string>
}> {
  constructor(props: Props) {
    super(props)
    this.state = {
      autoFocusIndex: 0,
      characters: ['', '', '', '', '', '']
    }
  }

  getCode = () => this.state.characters.join('')

  onCancel = () => {
    this.props.onCancel()
  }

  onCharacterChanged = (target: HTMLInputElement, index: number) => {
    const value = target.value.toUpperCase()

    const characters = this.state.characters

    if (!value || (/\W/.test(value) && /\D/.test(value))) {
      target.value = ''

      characters[index] = ''

      this.setState({
        characters
      })
    }
    else {
      if (value !== target.value) {
        target.value = value
      }

      characters[index] = value

      this.setState({
        autoFocusIndex: index === 5 ? 5 : ++index,
        characters
      })
    }
    this.props.onCode(this.getCode())
  }

  onKeyDown = (keyCode: number) => {
    if (keyCode === 27) {
      this.onCancel()
    }
  }

  renderInput(index: number) {
    return (
      <AuthorizeInput
        autoFocus={this.state.autoFocusIndex === index}
        onChanged={e => this.onCharacterChanged(e, index)}
        onKeyDown={keyCode => this.onKeyDown(keyCode)}
        value={this.state.characters[index]}
      />
    )
  }

  render() {
    return (
      <Box display='flex' flexWrap='wrap' justifyContent='center'>
        <Box display='flex' flexDirection='row' flexWrap='nowrap' paddingBottom='10px'>
          {this.renderInput(0)}
          {this.renderInput(1)}
          {this.renderInput(2)}
        </Box>
        <Box display='flex' flexDirection='row' flexWrap='nowrap' paddingBottom='10px'>
          {this.renderInput(3)}
          {this.renderInput(4)}
          {this.renderInput(5)}
        </Box>
      </Box>
    )
  }
}

export default RemoteControlAuthorize
