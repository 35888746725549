import React from 'react'
import AccountCircleIcon from '@material-ui/icons/AccountCircle'
import { Box, Hidden, Menu, MenuItem, Typography, makeStyles, useTheme, useMediaQuery } from '@material-ui/core'
import { ArrowDropDown, ArrowRight } from '@material-ui/icons'
import * as keycloak from '../keycloak';

const useStyles = makeStyles({
  username: {
    marginLeft: 10,
    whiteSpace: 'nowrap'
  }
})

function ProfileMenu() {
  const [anchorEl, setAnchorEl] = React.useState(null)

  const handleClose = () => {
    setAnchorEl(null)
  }
  const classes = useStyles()
  const theme = useTheme()
  const mdDown = useMediaQuery(theme.breakpoints.down('md'))
  const username = keycloak.getUserName()

  return (
    <React.Fragment>
      <Box
        data-cy='profile'
        display="flex"
        flexDirection="row"
        p={1} m={1}
        onClick={(e) => {
          setAnchorEl(e.currentTarget as any)
        }}
        style={{
          color: mdDown ? '#fff' : undefined
        }}
      >
        <AccountCircleIcon />
        <Hidden mdDown>
          <Typography className={classes.username}>{username}</Typography>
        </Hidden>
        {anchorEl ? <ArrowDropDown /> : <ArrowRight />}
      </Box>
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        getContentAnchorEl={null}
        id="simple-menu"
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
      >
        <MenuItem
          button
          component="a"
          data-cy='logout'
          onClick={() => keycloak.logout()}
        >Logout</MenuItem>
      </Menu>
    </React.Fragment>
  )
}

export default ProfileMenu
