import React from 'react'
import {
  Dialog,
} from '@material-ui/core'
import ControllerActivatedDialog from './CcsControllerActivatedDialog';
import ControllerDialogForm from './CcsControllerDialogForm';

function ControllersDialog({
  open = false,
  setOpen = (type: null) => { },
  fetchControllers = () => { }
}) {
  const [activatedController, setActivatedController] = React.useState(false)
  const handleCloseDialog = () => {
    fetchControllers()
    setOpen(null)
  }

  return (
    <Dialog
      fullWidth={true}
      onClose={handleCloseDialog}
      maxWidth="sm"
      open={open}>

      {activatedController ?
        <ControllerActivatedDialog
          handleCloseDialog={handleCloseDialog}
          handleAddAnother={() => setActivatedController(false)} /> :
        <ControllerDialogForm
          handleCloseDialog={handleCloseDialog}
          activateController={setActivatedController}
        />
      }

    </Dialog>
  )
}

export default ControllersDialog