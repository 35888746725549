import * as keycloak from '../keycloak';

const CCS_URL = process.env.REACT_APP_CCS_API_URL

export interface Controller {
  code: string,
  deleted: boolean,
  desired: {
    event: string,
    point: string,
    timeSource: string,
  }
  disabledreason: string,
  enabled: boolean,
  event: string,
  mac: string,
  onlinedate: number,
  offlinedate: number,
  online: boolean,
  point: string,
  serial: number,
  timerid: number,
  timeSource: string,
  ttl: number,
  type: string,
  updated: number,
}

export async function getControllerList() {
  const response = await fetch(CCS_URL + '/controllers', {
    headers: {
      Authorization: 'Bearer ' + keycloak.getToken()
    }
  })

  const text = await response.text();
  console.log('response', response.status, text)
  if (response.status === 200) {
    return {
      controllers: JSON.parse(text) as Controller[]
    }
  }
  throw new Error(text)
}

interface ControllerConfig {
  event: string
  point: string
  timeSource: string
}

export async function requestCode(config: ControllerConfig) {
  const response = await fetch(CCS_URL + '/controllers/request-code', {
    method: 'POST',
    headers: {
      Authorization: 'Bearer ' + keycloak.getToken(),
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(config)
  })
  console.log('response', response.status)
  const text = await response.text()
  console.log('text', text)
  if (response.status === 200) {
    return {
      code: JSON.parse(text)
    }
  }
  throw new Error(text)
}

export async function isActivated(code: string) {
  const response = await fetch(CCS_URL + '/code/is-activated ', {
    method: 'POST',
    headers: {
      Authorization: 'Bearer ' + keycloak.getToken(),
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({code})
  })

  const text = await response.text();
  console.log('response', response.status, text)
  if (response.status === 200) {
    return {
      body: JSON.parse(text)
    }
  }
  throw new Error(text)
}
