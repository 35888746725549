import React from 'react';
import {
  AppBar,
  Box,
  Hidden,
  IconButton,
  makeStyles,
  Toolbar,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';

import './App.css';
import { DRAWER_WIDTH } from './AppDrawer';
import { useDispatch, useSelector } from 'react-redux';
import ProfileMenu from './ProfileMenu';
import { Menu } from '@material-ui/icons';
import { setOpen } from '../reducers/open';

const useStyles = makeStyles(theme => ({
  appBar: {
    backgroundColor: '#fff',
    marginLeft: DRAWER_WIDTH,
    width: `calc(100% - ${DRAWER_WIDTH}px)`,
    [theme.breakpoints.down('md')]: {
      backgroundColor: 'rgb(44, 49, 78)',
      marginLeft: 0,
      width: '100%'
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
  }
}))

function AppHeader() {
  const classes = useStyles()
  const theme = useTheme()
  const mdDown = useMediaQuery(theme.breakpoints.down('md'))
  const open = useSelector<{ open: { drawer: boolean } }, boolean>(_ => _.open.drawer)
  const dispatch = useDispatch()
  const handleDrawerToggle = () => {
    dispatch(setOpen({
      key: 'drawer',
      open: !open
    }))
  }
  return (
    <AppBar
      elevation={0}
      className={classes.appBar}
    >
      <Toolbar>
        <Hidden lgUp>
          <IconButton
            aria-label="open drawer"
            className={classes.menuButton}
            edge="start"
            onClick={handleDrawerToggle}
            style={{
              color: mdDown ? '#fff' : undefined
            }}
          >
            <Menu />
          </IconButton>
        </Hidden>
        <Box display='flex' justifyContent='flex-end' width='100%'>
          <ProfileMenu />
        </Box>
      </Toolbar>
    </AppBar>
  )
}

export default AppHeader
