import React from 'react'
import {
  Snackbar,
  Typography
} from '@material-ui/core'
import { Alert } from '@material-ui/lab'

function DialogError (
  { error, handleCloseError}: 
  { error?: string, handleCloseError: () => void}) {
  return (
    <Snackbar
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      data-cy="dialogSnackbar"
      open={!!error}>
      <Alert severity="error" onClose={handleCloseError}>
        <Typography>
          {error}
        </Typography>
      </Alert>
    </Snackbar>
  )
}

export default DialogError
