import {
  Button,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@material-ui/core'
import React from 'react'
import { useHistory } from 'react-router-dom';
import { IAccountInfo } from './UserSearchTypes';

const useStyles = makeStyles({
  table: {
    minWidth: 400,
  },
});

function UserSearchTable({
  users,
}: {
  users: IAccountInfo[],
}) {
  const classes = useStyles();
  const history = useHistory()
  return (
    <TableContainer component={Paper}>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell>Username</TableCell>
            <TableCell>Email</TableCell>
            <TableCell>First Name</TableCell>
            <TableCell>Last Name</TableCell>
            <TableCell>&nbsp;</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {users.map(user => (
            <TableRow key={user.id}>
              <TableCell>{user.username}</TableCell>
              <TableCell>{user.email}</TableCell>
              <TableCell>{user.firstName}</TableCell>
              <TableCell>{user.lastName}</TableCell>
              <TableCell>
                <Button
                  data-cy='view'
                  data-id={user.id}
                  onClick={() => history.push('/userinfo/' + user.id)}
                >View</Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default UserSearchTable