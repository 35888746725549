import React, { MouseEventHandler } from 'react'
import {
	IconButton,
	makeStyles,
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'

const useStyles = makeStyles(theme => {
	return ({
		headerCloseButton: {
			position: 'absolute',
			right: 8,
			top: 8,
			color: theme.palette.info.main
		}
	})
})

function DialogHeaderClose({
	handleCloseDialog
}:
	{
		handleCloseDialog?: MouseEventHandler
	}) {
	const classes = useStyles();
	return (
		<IconButton
			aria-label={'close'}
			onClick={handleCloseDialog}
			className={classes.headerCloseButton}>
			<CloseIcon />
		</IconButton>
	)
}

export default DialogHeaderClose