import { Box, Button, makeStyles, Typography } from '@material-ui/core'
import { AddCircleOutline, PhonelinkRing, Update } from '@material-ui/icons'
import React from 'react'
import { useSelector } from 'react-redux'
import { IHeader } from '../reducers/header'
import { DialogType } from '../types'

const useStyles = makeStyles((theme) => ({
  baseButton: {
    whiteSpace: 'nowrap', 
    fontSize: '16px',
    fontWeight: 'bold',
    borderColor: '#00B0FF',

    [theme.breakpoints.down(1300)]: {
      width: '49%'
    }, 
    [theme.breakpoints.down('sm')]: {
      fontSize: '12px'
    }
  },
  buttonDiv: {
    display: 'flex',
    flexDirection: 'row',
    gap: '13px',
    justifyContent:'flex-end',

    [theme.breakpoints.down(1300)]: {
      width: '100%',
      justifyContent:'space-between'
    }
  }
}))

const AddControllerToolbar = ({
  setDialogOpen,
  onUpdate,
  display = { ccs: true, remote: true }
} : {
  setDialogOpen: (type: DialogType) => void
  onUpdate: () => void
  display?: { ccs: boolean, remote: boolean }
}) => {
  const classes = useStyles()
  const { lastUpdated, title } = useSelector<{ header: IHeader }, IHeader>(({
    header: {
      lastUpdated,
      subtitle,
      title
    }
  }) => ({
    lastUpdated,
    subtitle,
    title
  }))

  return (
    <Box display='flex' flexDirection='row' justifyContent='space-between' flexWrap='wrap' paddingBottom={2.5}>
      <Box display='flex' flexDirection='row' flexWrap='wrap' justifyContent='flex-beginning' alignSelf='center' gridGap='5px'>
        <Box paddingRight='10px'>
          <Typography variant='h5'>{title}</Typography>
        </Box>
        <Box display='flex' alignSelf='center'>
          <Typography variant='body2'>Last Updated {new Date(lastUpdated).toLocaleTimeString()}</Typography>
        </Box>
        <Button
          data-cy='refreshControllers'
          startIcon={<Update/>}
          onClick={onUpdate}
          color='primary'
          variant='outlined'
          >Update</Button>
      </Box>
      <Box className={classes.buttonDiv}>
        { display.ccs && 
          <Button
            data-cy='getCode'
            onClick={() => setDialogOpen(DialogType.CCS)}
            startIcon={<PhonelinkRing />}
            className={`${classes.baseButton}`}
            color='primary'
            variant='outlined'
            size='large'
          >Add MobileTrack</Button>
        }

        { display.remote && 
          <Button
            data-cy='addController'
            onClick={() => setDialogOpen(DialogType.REMOTE)}
            startIcon={<AddCircleOutline />}
            className={`${classes.baseButton}`}
            color='primary'
            variant='contained'
            size='large'
          >Add Controller</Button>
        }
      </Box>
    </Box>
  )
}

export default AddControllerToolbar