import Keycloak from "keycloak-js";

const _kc = Keycloak(process.env.REACT_APP_ENV === 'prod'
  ? require('./keycloak.prod.json')
  : require('./keycloak.stage.json')
);

export function init(onAuthenticated = () => { }) {
  console.log('init', _kc)
  _kc.init({
    // onLoad: 'login-required',
    onLoad: 'check-sso',
    checkLoginIframe: false,
    // redirectUri: window.location.href + '?redirectUri=' //+ redirectUri
    // silentCheckSsoRedirectUri: window.location.origin + '/silent-check-sso.html',
  })
    .then(authenticated => {
      console.log('authenticated', authenticated)
      if (authenticated) {
        console.log('token', _kc.tokenParsed, _kc.resourceAccess)
        onAuthenticated()
      }
      else {
        _kc.login()
      }
    })
    .catch(console.error)
}

export function logout() {
  _kc.logout()
}

type TokenParsed = Keycloak.KeycloakTokenParsed & { name: string }

export function getUserName() {
  const tokenParsed = _kc.tokenParsed as TokenParsed
  return tokenParsed?.name
}

export function getToken() {
  return _kc.token
}

export function hasResourceRole(role: string, resource: string) {
  return _kc.hasResourceRole(role, resource)
}

export default {
  getToken,
  getUserName,
  hasResourceRole,
  init,
  logout,
}