import React from 'react';
import { Provider as ReduxProvider } from 'react-redux'
import AppRoot from './AppRoot';
import store from '../store';
import './App.css'

store.subscribe(() => {
  console.log('state', store.getState())
})

function App() {
  return (
    <ReduxProvider store={store}>
      <AppRoot />
    </ReduxProvider>
  );
}

export default App;
