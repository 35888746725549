import { makeStyles } from '@material-ui/core';
import React from 'react';
import { useDispatch } from 'react-redux';
import { updateTitle } from '../reducers/header';

const useStyles = makeStyles({
  container: {
    margin: 20,
  },
});

function NotFoundPage() {
  const classes = useStyles();
  const dispatch = useDispatch()
  React.useEffect(() => {
    dispatch(updateTitle({
      subtitle: 'Not Found',
      title: '404',
    }))
  }, [dispatch])
  return (
    <div className={classes.container}>
      Page Not Found.
    </div>
  )
}

export default NotFoundPage