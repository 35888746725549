import React, {
	FormEvent,
	MouseEventHandler,
	useEffect
} from 'react'
import {
	Box,
	Button,
	DialogActions,
	DialogContent,
	DialogTitle,
	Divider,
	makeStyles,
	MenuItem,
	TextField,
	Typography
} from '@material-ui/core'
import PhonelinkRingIcon from '@material-ui/icons/PhonelinkRing';
import { requestCode, isActivated } from './ccsControllers.data'
import DialogHeaderClose from '../shared/DialogClose';
import DialogSuccess from '../shared/DialogSuccess';
import DialogError from '../shared/DialogError';
import DialogProgress from '../shared/DialogProgress';
import { ERROR_500 } from '../constants';

export interface Code {
	code: string,
	ttl: number
}

export interface CodeInfo {
	event: string,
	point: string,
	timeSource: string
}

const useStyles = makeStyles(theme => {
	return ({
		form: {
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'center'
		},
		inputLabel: {
			color: '#00B0FF',
			fontWeight: 'bold',
			textTransform: 'uppercase'
		},
		dialogActions: {
			justifyContent: "space-between",
			margin: "15px 5px 5px"
		}
	})
})

const DialogCodeFields = ({ codes }: { codes?: Code[] }) => {
	if (!codes || codes.length === 0) {
		return null
	}
	return (
		<Box color='#00B0FF' maxHeight='180px' style={{ overflowY: "auto" }}>
			<Typography variant='body2' color='inherit'><strong>ENTER CODE IN MOBILETRACK APP</strong></Typography>
			{codes.map(code => { return <Typography variant='h4' align='right'>{code.code}</Typography> })}
		</Box>
	)
}

function ControllerDialogForm({
	handleCloseDialog,
	activateController,
}:
	{
		handleCloseDialog: MouseEventHandler
		activateController: (value: boolean) => void
	}) {
	const classes = useStyles();
	const [loading, setLoading] = React.useState(false)
	const [error, setError] = React.useState<string | undefined>()
	const [newCode, setNewCode] = React.useState<string | undefined>()
	const [codes, setCodes] = React.useState<Code[]>([])
	const [event, setEvent] = React.useState('')
	const [point, setPoint] = React.useState('')
	const [timeSource, setTimeSource] = React.useState('ntp') // defaulting because find it annoying to select every time
	const [prevCodeInfo, setPrevCodeInfo] = React.useState<CodeInfo>()
	const submitRef = React.createRef<HTMLInputElement>()

	const onFormSubmit = (e: FormEvent) => {
		e.preventDefault()

		if (!event.trim() || !point.trim()) {
			setError('Event and Point name cannot be blank')
			return
		}

		const codeInfo: CodeInfo = { event, point, timeSource }

		// do not await, or form validation is borked
		setLoading(true)
		requestCode(codeInfo)
			.then(response => {
				const codelist = (JSON.stringify(codeInfo) === JSON.stringify(prevCodeInfo)) ? codes : []
				codelist.push(response.code)

				setError('')
				setCodes(codelist)
				setNewCode(`Code ${response.code.code} successfully added!`)
				setPrevCodeInfo(codeInfo)
			})
			.catch(err => { console.error(err); setError(ERROR_500) })
			.finally(() => setLoading(false))
	}

	useEffect(() => {
		if (!codes || codes.length === 0) return
		const fetchInterval = setInterval(() => {
			isActivated(codes[codes.length - 1].code)
				.then(json => {
					if (json.body.mac) {
						activateController(true)
						clearInterval(fetchInterval)
					}
				})
				.catch(err => { console.error(err); setError(ERROR_500) })
				.finally(() => setLoading(false))
		}, 3000)
		return () => {
			clearInterval(fetchInterval);
		}
	}, [codes, newCode, activateController, setError, setLoading])

	return (
		<>

			<DialogProgress open={loading} />
			<DialogError error={error} handleCloseError={() => setError(undefined)} />
			<DialogSuccess message={newCode} onClose={() => setNewCode(undefined)} />

			<DialogTitle>
				<Box display='flex' justifyContent='center' color='#00B0FF' gridGap='15px'>
					<Box display='flex' flexDirection='column' justifyContent='center' fontSize='3rem'>
						<PhonelinkRingIcon fontSize='inherit' />
					</Box>

					<Box>
						<Typography variant='h4'>Activate MobileTrack</Typography>
						<Typography variant='subtitle1' color='textPrimary'>Set Event, Point, and Time Source below *</Typography>
					</Box>
				</Box>
				<DialogHeaderClose handleCloseDialog={handleCloseDialog} />
			</DialogTitle>

			<form
				className={classes.form}
				onSubmit={onFormSubmit}>

				<input type="submit" ref={submitRef} style={{ display: 'none' }} />

				<DialogContent>

					<Box display="flex" flexDirection="row" justifyContent="space-between">

						<Box display="flex" flexDirection="column" width='45%' gridGap='15px'>
							<TextField
								InputLabelProps={({ className: classes.inputLabel })}
								label="Event Name *"
								variant="standard"
								inputProps={{ maxLength: 16 }}
								// inputProps 'pattern' does not prevent special characters from being input, just invalidates the submission
								// alphanumeric & underscore & space allowed
								onChange={e => setEvent(e.target.value.replace(/[^\w ]/g, ""))}
								value={event} />

							<TextField
								InputLabelProps={({ className: classes.inputLabel })}
								label="Point *"
								inputProps={{ maxLength: 16 }}
								onChange={e => setPoint(e.target.value.replace(/[^\w ]/g, ""))}
								value={point} />

							<TextField
								InputLabelProps={({ className: classes.inputLabel })}
								label="Time Source *"
								onChange={e => setTimeSource(e.target.value)}
								select
								value={timeSource}>
								<MenuItem value="gps">GPS</MenuItem>
								<MenuItem value="ntp">NTP</MenuItem>
								<MenuItem value="phone">Device</MenuItem> /** Changed from 'Manual' b/c that doesn't make sense without a time input */
							</TextField>
						</Box>

						<Divider orientation="vertical" />

						<DialogCodeFields codes={codes} />

					</Box>

				</DialogContent>

				<DialogActions className={classes.dialogActions}>
					<Button onClick={handleCloseDialog}>{(!codes || codes.length === 0) ? "Cancel" : "View Controllers"}</Button>
					<Button
						data-cy="submit"
						onClick={() => submitRef.current?.click()}
						variant="contained"
						color='primary'
					>
						{(!codes || codes.length === 0) ? "Get Code" : "Get New Code"}
					</Button>
				</DialogActions>
			</form>
		</>
	)
}

export default ControllerDialogForm