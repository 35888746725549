export interface IAccountInfo {
  username: string,
  email: string,
  id: string,
  firstName: string,
  lastName: string,
}

export interface IUserInfo {
  error?: string
  ctlive: IAccountInfo
  keycloak: IAccountInfo
  timersportal: IAccountInfo
}

export const DEFAULT_USER_INFO = {
  email: '',
  error: undefined,
  ctlive: { username: '', email: '', id: '', firstName: '', lastName: '' },
  keycloak: { username: '', email: '', id: '', firstName: '', lastName: '' },
  timersportal: { username: '', email: '', id: '', firstName: '', lastName: '' }
}