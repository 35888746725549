import React, { Component } from 'react'

import './RemoteControlAuthorizeInput.css'

class AuthorizeInput extends Component<{
  autoFocus: boolean
  onChanged: (target: HTMLInputElement) => void
  onKeyDown: (keyCode: number) => void
  value?: string
}> {

  _input: HTMLInputElement | null | undefined

  componentDidUpdate() {
    if (this.props.autoFocus) {
      this._input?.focus()
    }
    else this._input?.blur()
  }

  render() {
    return (
      <input
        autoFocus={this.props.autoFocus}
        className='authorizeInput'
        maxLength={1}
        onChange={() => {}}
        onFocus={e => e.target.select()}
        onInput={e => this.props.onChanged(e.target as HTMLInputElement)}
        onKeyDown={e => this.props.onKeyDown(e.keyCode)}
        ref={c => this._input = c}
        required
        value={this.props.value}
      />
    )
  }
}

export default AuthorizeInput
