import { makeStyles } from '@material-ui/core';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { updateTitle } from '../reducers/header';
import FormProgress from '../shared/FormProgress';
import { DEFAULT_USER_INFO, IUserInfo } from '../userSearch/UserSearchTypes';
import { getKeycloakUserInfo } from './UserInfoData';
import UserInfoTable from './UserInfoTable';

const useStyles = makeStyles({
  container: {
    margin: 20,
  },
});

function UserInfoPage() {
  const classes = useStyles();
  const dispatch = useDispatch()
  React.useEffect(() => {
    dispatch(updateTitle({
      subtitle: 'User Info',
      title: 'Admin',
    }))
  }, [dispatch])
  const { userId } = useParams<{ userId: string }>()
  const [loading, setLoading] = React.useState(false)
  const [userInfo, setUserInfo] = React.useState<IUserInfo>(DEFAULT_USER_INFO)
  React.useEffect(() => {
    if (!userId) return
    setLoading(true)
    getKeycloakUserInfo(userId)
      .then(keycloak => setUserInfo(userInfo => ({
        ...userInfo,
        keycloak
      })))
      .finally(() => setLoading(false))
  }, [userId])
  return (
    <div className={classes.container}>
      <FormProgress open={loading} />
      <UserInfoTable userInfo={userInfo} />
    </div>
  )
}

export default UserInfoPage