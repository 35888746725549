import React from 'react'
import {
  makeStyles,
  Backdrop,
  CircularProgress
} from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  backdrop: {
    position: 'absolute',
    zIndex: theme.zIndex.drawer + 1,
  },
  progress: {
    alignSelf: 'center'
  }
}))

function FormProgress({ open = false }) {
  const classes = useStyles()
  return (
    <Backdrop className={classes.backdrop} open={open} >
      <CircularProgress className={classes.progress} />
    </Backdrop>
  )
}

export default FormProgress
