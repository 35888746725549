import { Grid, makeStyles, Snackbar, Typography } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import React from 'react';
import { useDispatch } from 'react-redux';
import FormProgress from '../shared/FormProgress';
import ControllersTable from './CcsControllersTable';
import { Controller, getControllerList } from './ccsControllers.data';
import ControllersDialog from './CcsControllersDialog';
import { updateLastUpdated, updateTitle } from '../reducers/header';
import RemoteControlDialog from '../remoteControl/RemoteControlDialog';
import { DialogType } from '../types'
import AddControllerToolbar from '../shared/AddControllerToolbar';
import { ERROR_500 } from '../constants';

const useStyles = makeStyles({
  container: {
    margin: 20,
  },
});

function CcsControllersPage() {
  const classes = useStyles();
  const dispatch = useDispatch()

  const [error, setError] = React.useState<string | undefined>()
  const [controllers, setControllers] = React.useState<Controller[]>([])
  const [loading, setLoading] = React.useState(false)
  const [openDialog, setDialogOpen] = React.useState<DialogType | null>(null)
  const fetchControllers = React.useCallback(() => {
    setLoading(true)
      getControllerList()
        .then(json => {
          setError('')
          const controllers = json.controllers.filter(controller => !!controller)
          setControllers(controllers)
          dispatch(updateLastUpdated())
        })
        .catch(err => { console.error(err); setError(ERROR_500) })
        .finally(() => setLoading(false))
  }, [dispatch])

  React.useEffect(() => {
    dispatch(updateTitle({
      subtitle: 'Controllers',
      title: 'Controller Status',
    }))
  }, [dispatch])

  React.useEffect(() => {
    fetchControllers()
    const fetchInterval = setInterval(fetchControllers, 300000)
    return () => {
      clearInterval(fetchInterval);
    }
  }, [fetchControllers])

  return (
    <div className={classes.container}>
      <FormProgress open={loading} />
      <AddControllerToolbar setDialogOpen={setDialogOpen} onUpdate={fetchControllers}/>
      <Grid container>
        <Grid item xs={12}>
          <ControllersTable controllers={controllers}/>
        </Grid>
      </Grid> 
      <Snackbar
        open={!!error}
      >
        <Alert severity='error'>
          <Typography>
            {error}
          </Typography>
        </Alert>
      </Snackbar>
      <ControllersDialog
        open={openDialog === DialogType.CCS}
        setOpen={setDialogOpen}
        fetchControllers={fetchControllers}
      />
      <RemoteControlDialog
        open={openDialog === DialogType.REMOTE}
        setOpen={setDialogOpen}
      />
    </div>
  )
}

export default CcsControllersPage